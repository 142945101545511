const assetsMyPortfolioLibrary = [
    {
        "Asset Name": "沪深300ETF-510300",
        "Monthly Mean Return": "0.16%",
        "Monthly Std Dev": "5.44%",
        "Monthly Sharpe Ratio": "0.0295",
        "Annual Mean Return": "1.94%",
        "Annual Std Dev": "18.85%",
        "Annual Sharpe Ratio": "0.1032",
        "Monthly Returns": [
            {
                "2020-01-23": "-2.44%"
            },
            {
                "2020-02-28": "-1.57%"
            },
            {
                "2020-03-31": "-6.72%"
            },
            {
                "2020-04-30": "6.50%"
            },
            {
                "2020-05-29": "-1.06%"
            },
            {
                "2020-06-30": "8.07%"
            },
            {
                "2020-07-31": "13.69%"
            },
            {
                "2020-08-31": "2.60%"
            },
            {
                "2020-09-30": "-4.68%"
            },
            {
                "2020-10-30": "2.40%"
            },
            {
                "2020-11-30": "5.76%"
            },
            {
                "2020-12-31": "5.09%"
            },
            {
                "2021-01-29": "2.49%"
            },
            {
                "2021-02-26": "-0.36%"
            },
            {
                "2021-03-31": "-5.24%"
            },
            {
                "2021-04-30": "1.54%"
            },
            {
                "2021-05-31": "4.10%"
            },
            {
                "2021-06-30": "-1.54%"
            },
            {
                "2021-07-30": "-7.30%"
            },
            {
                "2021-08-31": "0.14%"
            },
            {
                "2021-09-30": "1.14%"
            },
            {
                "2021-10-29": "0.94%"
            },
            {
                "2021-11-30": "-1.40%"
            },
            {
                "2021-12-31": "1.97%"
            },
            {
                "2022-01-28": "-7.65%"
            },
            {
                "2022-02-28": "0.38%"
            },
            {
                "2022-03-31": "-7.72%"
            },
            {
                "2022-04-29": "-4.83%"
            },
            {
                "2022-05-31": "1.84%"
            },
            {
                "2022-06-30": "10.25%"
            },
            {
                "2022-07-29": "-6.38%"
            },
            {
                "2022-08-31": "-1.87%"
            },
            {
                "2022-09-30": "-6.60%"
            },
            {
                "2022-10-31": "-7.84%"
            },
            {
                "2022-11-30": "9.71%"
            },
            {
                "2022-12-30": "0.70%"
            },
            {
                "2023-01-31": "7.26%"
            },
            {
                "2023-02-28": "-2.12%"
            },
            {
                "2023-03-31": "-0.52%"
            },
            {
                "2023-04-28": "-0.63%"
            },
            {
                "2023-05-31": "-5.53%"
            },
            {
                "2023-06-30": "2.09%"
            },
            {
                "2023-07-31": "5.21%"
            },
            {
                "2023-08-31": "-5.99%"
            },
            {
                "2023-09-28": "-1.96%"
            },
            {
                "2023-10-31": "-3.19%"
            },
            {
                "2023-11-30": "-2.25%"
            },
            {
                "2023-12-29": "-1.78%"
            },
            {
                "2024-01-31": "-5.92%"
            },
            {
                "2024-02-29": "8.71%"
            },
            {
                "2024-03-29": "0.66%"
            },
            {
                "2024-04-26": "2.04%"
            },
            {
                "2024-05-30": "-0.46%"
            },
            {
                "2024-06-28": "-2.64%"
            },
            {
                "2024-07-31": "0.59%"
            },
            {
                "2024-08-30": "-3.30%"
            },
            {
                "2024-09-30": "20.71%"
            },
            {
                "2024-10-31": "-5.34%"
            },
            {
                "2024-11-30": "0.76%"
            },
            {
                "2024-12-31": "0.45%"
            },
            {
                "2025-01-27": "-2.86%"
            },
            {
                "2025-02-28": "2.23%"
            },
            {
                "2025-03-31": "-0.25%"
            }
        ],
        "Annual Returns": {
            "2020": "29.09%",
            "2021": "-4.03%",
            "2022": "-20.14%",
            "2023": "-9.77%",
            "2024": "14.53%",
            "2025YTD": "-0.94%"
        }
    },
    {
        "Asset Name": "国债ETF-511010",
        "Monthly Mean Return": "0.25%",
        "Monthly Std Dev": "0.67%",
        "Monthly Sharpe Ratio": "0.3775",
        "Annual Mean Return": "3.06%",
        "Annual Std Dev": "2.31%",
        "Annual Sharpe Ratio": "1.3258",
        "Monthly Returns": [
            {
                "2020-01-23": "0.79%"
            },
            {
                "2020-02-28": "0.96%"
            },
            {
                "2020-03-31": "1.01%"
            },
            {
                "2020-04-30": "2.63%"
            },
            {
                "2020-05-29": "-2.01%"
            },
            {
                "2020-06-30": "-1.53%"
            },
            {
                "2020-07-31": "-0.20%"
            },
            {
                "2020-08-31": "-1.04%"
            },
            {
                "2020-09-30": "0.18%"
            },
            {
                "2020-10-30": "0.22%"
            },
            {
                "2020-11-30": "-0.14%"
            },
            {
                "2020-12-31": "0.44%"
            },
            {
                "2021-01-29": "0.08%"
            },
            {
                "2021-02-26": "0.08%"
            },
            {
                "2021-03-31": "0.35%"
            },
            {
                "2021-04-30": "0.55%"
            },
            {
                "2021-05-31": "0.25%"
            },
            {
                "2021-06-30": "0.50%"
            },
            {
                "2021-07-30": "1.06%"
            },
            {
                "2021-08-31": "0.17%"
            },
            {
                "2021-09-30": "0.25%"
            },
            {
                "2021-10-29": "-0.53%"
            },
            {
                "2021-11-30": "0.68%"
            },
            {
                "2021-12-31": "0.76%"
            },
            {
                "2022-01-28": "0.78%"
            },
            {
                "2022-02-28": "-0.35%"
            },
            {
                "2022-03-31": "-0.03%"
            },
            {
                "2022-04-29": "0.32%"
            },
            {
                "2022-05-31": "0.35%"
            },
            {
                "2022-06-30": "-0.08%"
            },
            {
                "2022-07-29": "0.61%"
            },
            {
                "2022-08-31": "0.79%"
            },
            {
                "2022-09-30": "-0.57%"
            },
            {
                "2022-10-31": "0.69%"
            },
            {
                "2022-11-30": "-0.89%"
            },
            {
                "2022-12-30": "0.46%"
            },
            {
                "2023-01-31": "-0.21%"
            },
            {
                "2023-02-28": "0.09%"
            },
            {
                "2023-03-31": "0.41%"
            },
            {
                "2023-04-28": "0.51%"
            },
            {
                "2023-05-31": "0.83%"
            },
            {
                "2023-06-30": "0.24%"
            },
            {
                "2023-07-31": "0.15%"
            },
            {
                "2023-08-31": "0.47%"
            },
            {
                "2023-09-28": "-0.35%"
            },
            {
                "2023-10-31": "0.14%"
            },
            {
                "2023-11-30": "0.03%"
            },
            {
                "2023-12-29": "0.96%"
            },
            {
                "2024-01-31": "0.63%"
            },
            {
                "2024-02-29": "0.31%"
            },
            {
                "2024-03-29": "0.22%"
            },
            {
                "2024-04-26": "0.29%"
            },
            {
                "2024-05-30": "0.52%"
            },
            {
                "2024-06-28": "0.65%"
            },
            {
                "2024-07-31": "0.56%"
            },
            {
                "2024-08-30": "0.09%"
            },
            {
                "2024-09-30": "0.04%"
            },
            {
                "2024-10-31": "0.42%"
            },
            {
                "2024-11-30": "0.81%"
            },
            {
                "2024-12-31": "1.19%"
            },
            {
                "2025-01-27": "0.20%"
            },
            {
                "2025-02-28": "-0.88%"
            },
            {
                "2025-03-31": "-0.05%"
            }
        ],
        "Annual Returns": {
            "2020": "1.23%",
            "2021": "4.27%",
            "2022": "2.08%",
            "2023": "3.31%",
            "2024": "5.88%",
            "2025YTD": "-0.73%"
        }
    },
    {
        "Asset Name": "纳指ETF-513100",
        "Monthly Mean Return": "1.50%",
        "Monthly Std Dev": "7.05%",
        "Monthly Sharpe Ratio": "0.2128",
        "Annual Mean Return": "19.56%",
        "Annual Std Dev": "24.40%",
        "Annual Sharpe Ratio": "0.8013",
        "Monthly Returns": [
            {
                "2020-01-23": "8.93%"
            },
            {
                "2020-02-28": "-2.47%"
            },
            {
                "2020-03-31": "-8.64%"
            },
            {
                "2020-04-30": "20.59%"
            },
            {
                "2020-05-29": "-0.19%"
            },
            {
                "2020-06-30": "1.07%"
            },
            {
                "2020-07-31": "3.98%"
            },
            {
                "2020-08-31": "29.03%"
            },
            {
                "2020-09-30": "-9.94%"
            },
            {
                "2020-10-30": "-6.67%"
            },
            {
                "2020-11-30": "1.55%"
            },
            {
                "2020-12-31": "1.34%"
            },
            {
                "2021-01-29": "-1.10%"
            },
            {
                "2021-02-26": "-1.87%"
            },
            {
                "2021-03-31": "1.95%"
            },
            {
                "2021-04-30": "6.70%"
            },
            {
                "2021-05-31": "-3.12%"
            },
            {
                "2021-06-30": "8.09%"
            },
            {
                "2021-07-30": "1.55%"
            },
            {
                "2021-08-31": "6.35%"
            },
            {
                "2021-09-30": "-5.36%"
            },
            {
                "2021-10-29": "3.60%"
            },
            {
                "2021-11-30": "4.37%"
            },
            {
                "2021-12-31": "0.34%"
            },
            {
                "2022-01-28": "-14.30%"
            },
            {
                "2022-02-28": "-2.50%"
            },
            {
                "2022-03-31": "9.71%"
            },
            {
                "2022-04-29": "-7.93%"
            },
            {
                "2022-05-31": "-4.09%"
            },
            {
                "2022-06-30": "-7.72%"
            },
            {
                "2022-07-29": "10.86%"
            },
            {
                "2022-08-31": "-1.46%"
            },
            {
                "2022-09-30": "-7.20%"
            },
            {
                "2022-10-31": "4.55%"
            },
            {
                "2022-11-30": "-0.82%"
            },
            {
                "2022-12-30": "-5.10%"
            },
            {
                "2023-01-31": "4.13%"
            },
            {
                "2023-02-28": "2.76%"
            },
            {
                "2023-03-31": "6.20%"
            },
            {
                "2023-04-28": "1.65%"
            },
            {
                "2023-05-31": "11.70%"
            },
            {
                "2023-06-30": "6.69%"
            },
            {
                "2023-07-31": "4.45%"
            },
            {
                "2023-08-31": "-0.78%"
            },
            {
                "2023-09-28": "-5.26%"
            },
            {
                "2023-10-31": "-2.41%"
            },
            {
                "2023-11-30": "9.39%"
            },
            {
                "2023-12-29": "5.20%"
            },
            {
                "2024-01-31": "4.20%"
            },
            {
                "2024-02-29": "2.86%"
            },
            {
                "2024-03-29": "2.84%"
            },
            {
                "2024-04-26": "-2.84%"
            },
            {
                "2024-05-30": "4.16%"
            },
            {
                "2024-06-28": "9.01%"
            },
            {
                "2024-07-31": "-3.12%"
            },
            {
                "2024-08-30": "-0.84%"
            },
            {
                "2024-09-30": "-0.56%"
            },
            {
                "2024-10-31": "3.55%"
            },
            {
                "2024-11-30": "5.00%"
            },
            {
                "2024-12-31": "6.33%"
            },
            {
                "2025-01-27": "-0.86%"
            },
            {
                "2025-02-28": "-6.44%"
            },
            {
                "2025-03-31": "-6.62%"
            }
        ],
        "Annual Returns": {
            "2020": "37.03%",
            "2021": "22.59%",
            "2022": "-25.48%",
            "2023": "51.79%",
            "2024": "34.29%",
            "2025YTD": "-13.39%"
        }
    },
    {
        "Asset Name": "黄金ETF-518880",
        "Monthly Mean Return": "1.22%",
        "Monthly Std Dev": "3.47%",
        "Monthly Sharpe Ratio": "0.3521",
        "Annual Mean Return": "15.68%",
        "Annual Std Dev": "12.01%",
        "Annual Sharpe Ratio": "1.3051",
        "Monthly Returns": [
            {
                "2020-01-23": "1.36%"
            },
            {
                "2020-02-28": "5.63%"
            },
            {
                "2020-03-31": "-1.02%"
            },
            {
                "2020-04-30": "4.46%"
            },
            {
                "2020-05-29": "3.15%"
            },
            {
                "2020-06-30": "1.29%"
            },
            {
                "2020-07-31": "7.72%"
            },
            {
                "2020-08-31": "-2.21%"
            },
            {
                "2020-09-30": "-3.61%"
            },
            {
                "2020-10-30": "-1.94%"
            },
            {
                "2020-11-30": "-6.90%"
            },
            {
                "2020-12-31": "6.12%"
            },
            {
                "2021-01-29": "-1.87%"
            },
            {
                "2021-02-26": "-4.37%"
            },
            {
                "2021-03-31": "-3.27%"
            },
            {
                "2021-04-30": "3.72%"
            },
            {
                "2021-05-31": "5.60%"
            },
            {
                "2021-06-30": "-6.37%"
            },
            {
                "2021-07-30": "3.79%"
            },
            {
                "2021-08-31": "-0.81%"
            },
            {
                "2021-09-30": "-3.98%"
            },
            {
                "2021-10-29": "1.86%"
            },
            {
                "2021-11-30": "-0.11%"
            },
            {
                "2021-12-31": "1.05%"
            },
            {
                "2022-01-28": "-1.35%"
            },
            {
                "2022-02-28": "5.37%"
            },
            {
                "2022-03-31": "1.21%"
            },
            {
                "2022-04-29": "2.84%"
            },
            {
                "2022-05-31": "-1.65%"
            },
            {
                "2022-06-30": "-1.52%"
            },
            {
                "2022-07-29": "-1.81%"
            },
            {
                "2022-08-31": "0.24%"
            },
            {
                "2022-09-30": "0.67%"
            },
            {
                "2022-10-31": "0.63%"
            },
            {
                "2022-11-30": "3.60%"
            },
            {
                "2022-12-30": "1.06%"
            },
            {
                "2023-01-31": "2.08%"
            },
            {
                "2023-02-28": "-1.33%"
            },
            {
                "2023-03-31": "6.63%"
            },
            {
                "2023-04-28": "0.40%"
            },
            {
                "2023-05-31": "1.56%"
            },
            {
                "2023-06-30": "-0.37%"
            },
            {
                "2023-07-31": "1.38%"
            },
            {
                "2023-08-31": "1.93%"
            },
            {
                "2023-09-28": "-1.29%"
            },
            {
                "2023-10-31": "4.15%"
            },
            {
                "2023-11-30": "-0.67%"
            },
            {
                "2023-12-29": "1.29%"
            },
            {
                "2024-01-31": "0.11%"
            },
            {
                "2024-02-29": "-0.13%"
            },
            {
                "2024-03-29": "10.31%"
            },
            {
                "2024-04-26": "3.12%"
            },
            {
                "2024-05-30": "1.08%"
            },
            {
                "2024-06-28": "-0.86%"
            },
            {
                "2024-07-31": "2.62%"
            },
            {
                "2024-08-30": "1.58%"
            },
            {
                "2024-09-30": "3.35%"
            },
            {
                "2024-10-31": "6.94%"
            },
            {
                "2024-11-30": "-2.95%"
            },
            {
                "2024-12-31": "0.08%"
            },
            {
                "2025-01-27": "4.62%"
            },
            {
                "2025-02-28": "3.90%"
            },
            {
                "2025-03-31": "8.81%"
            }
        ],
        "Annual Returns": {
            "2020": "13.78%",
            "2021": "-5.39%",
            "2022": "9.39%",
            "2023": "16.62%",
            "2024": "27.53%",
            "2025YTD": "18.28%"
        }
    },
    {
        "Asset Name": "我的收益-4%均衡@9%",
        "Monthly Mean Return": "0.83%",
        "Monthly Std Dev": "2.32%",
        "Monthly Sharpe Ratio": "0.3572",
        "Annual Mean Return": "10.43%",
        "Annual Std Dev": "8.05%",
        "Annual Sharpe Ratio": "1.2956",
        "Monthly Returns": [
            {
                "2020-01-23": "-0.37%"
            },
            {
                "2020-02-28": "-0.50%"
            },
            {
                "2020-03-31": "-3.23%"
            },
            {
                "2020-04-30": "6.48%"
            },
            {
                "2020-05-29": "-0.37%"
            },
            {
                "2020-06-30": "4.92%"
            },
            {
                "2020-07-31": "5.91%"
            },
            {
                "2020-08-31": "3.19%"
            },
            {
                "2020-09-30": "-4.89%"
            },
            {
                "2020-10-30": "0.30%"
            },
            {
                "2020-11-30": "2.51%"
            },
            {
                "2020-12-31": "1.65%"
            },
            {
                "2021-01-29": "0.21%"
            },
            {
                "2021-02-26": "0.45%"
            },
            {
                "2021-03-31": "0.14%"
            },
            {
                "2021-04-30": "1.19%"
            },
            {
                "2021-05-31": "1.39%"
            },
            {
                "2021-06-30": "0.07%"
            },
            {
                "2021-07-30": "0.38%"
            },
            {
                "2021-08-31": "1.69%"
            },
            {
                "2021-09-30": "-0.97%"
            },
            {
                "2021-10-29": "0.27%"
            },
            {
                "2021-11-30": "0.55%"
            },
            {
                "2021-12-31": "0.98%"
            },
            {
                "2022-01-28": "-2.90%"
            },
            {
                "2022-02-28": "1.74%"
            },
            {
                "2022-03-31": "-0.16%"
            },
            {
                "2022-04-29": "-3.08%"
            },
            {
                "2022-05-31": "0.22%"
            },
            {
                "2022-06-30": "0.35%"
            },
            {
                "2022-07-29": "0.45%"
            },
            {
                "2022-08-31": "-0.64%"
            },
            {
                "2022-09-30": "-4.95%"
            },
            {
                "2022-10-31": "-0.45%"
            },
            {
                "2022-11-30": "5.42%"
            },
            {
                "2022-12-30": "-1.28%"
            },
            {
                "2023-01-31": "4.18%"
            },
            {
                "2023-02-28": "-0.33%"
            },
            {
                "2023-03-31": "2.47%"
            },
            {
                "2023-04-28": "0.43%"
            },
            {
                "2023-05-31": "2.39%"
            },
            {
                "2023-06-30": "3.19%"
            },
            {
                "2023-07-31": "3.24%"
            },
            {
                "2023-08-31": "-1.73%"
            },
            {
                "2023-09-28": "-2.94%"
            },
            {
                "2023-10-31": "-1.00%"
            },
            {
                "2023-11-30": "2.88%"
            },
            {
                "2023-12-29": "1.37%"
            },
            {
                "2024-01-31": "-0.84%"
            },
            {
                "2024-02-29": "2.98%"
            },
            {
                "2024-03-29": "4.33%"
            },
            {
                "2024-04-26": "1.21%"
            },
            {
                "2024-05-30": "1.29%"
            },
            {
                "2024-06-28": "1.06%"
            },
            {
                "2024-07-31": "0.32%"
            },
            {
                "2024-08-30": "0.00%"
            },
            {
                "2024-09-30": "4.40%"
            },
            {
                "2024-10-31": "2.46%"
            },
            {
                "2024-11-30": "0.20%"
            },
            {
                "2024-12-31": "1.53%"
            },
            {
                "2025-01-27": "1.02%"
            },
            {
                "2025-02-28": "0.11%"
            },
            {
                "2025-03-31": "1.41%"
            }
        ],
        "Annual Returns": {
            "2020": "16.00%",
            "2021": "6.51%",
            "2022": "-5.51%",
            "2023": "14.79%",
            "2024": "20.50%",
            "2025YTD": "2.56%"
        }
    }
]





export default assetsMyPortfolioLibrary;