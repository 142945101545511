import Vue from 'vue';
import VueRouter from 'vue-router';
import assetsMyPortfolioLibrary from '/src/data/myPortfolioVsAnalysisLibrary.js'; // 导入数据

// 告诉Vue使用VueRouter
Vue.use(VueRouter);

// 计算投资周期
function calculateInvestmentDuration() {
  const startDate = new Date("2020-01-01");
  const endDate = new Date();
  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  if (months < 0) {
    years -= 1;
    months += 12;
  }
  return months === 0 ? `${years}年` : `${years}年${months}个月`;
}

// 计算总收益率
function calculatePerformance() {
  const portfolio = assetsMyPortfolioLibrary.find(
    (item) => item["Asset Name"] === "我的收益-4%均衡@9%"
  );
  const hs300 = assetsMyPortfolioLibrary.find(
    (item) => item["Asset Name"] === "沪深300ETF-510300"
  );

  if (portfolio && hs300) {
    const calculateTotalReturn = (annualReturns) => {
      return Object.values(annualReturns).reduce((total, annualReturn) => {
        const yearlyRate = parseFloat(annualReturn) / 100;
        return total * (1 + yearlyRate);
      }, 1) - 1;
    };

    const portfolioTotalReturn = calculateTotalReturn(portfolio["Annual Returns"]);
    const hs300TotalReturn = calculateTotalReturn(hs300["Annual Returns"]);

    return {
      portfolio: `+${(portfolioTotalReturn * 100).toFixed(2)}%`,
      hs300: `+${(hs300TotalReturn * 100).toFixed(2)}%`,
    };
  }

  return { portfolio: "--", hs300: "--" };
}

// 路由配置
const routes = [
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '有米-公测（警告：投资有风险）'
    },
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/AssetStructureOptimizer',
    name: 'asset-structure-optimizer',
    meta: {
      title: '有米-我的资产结构（警告：投资有风险）'
    },
    component: () => import('@/views/pat-lab/AssetStructureOptimizer.vue')
  },
  {
    path: '/PortfolioOptimizer',
    name: 'portfolio-optimizer',
    meta: {
      title: '有米-我的投资组合（警告：投资有风险）',
      meta: [
        {
          name: 'description',
          content: '选择最优投资组合查看资产比例和收益预测'
        }
      ]
    },
    component: () => import('@/views/PortfolioOptimizer.vue')
  },
  {
    path: '/MyFinancialTimeline',
    name: 'my-financial-timeline',
    component: () => import('@/views/MyFinancialTimeline.vue'),
    children: [
      {
        path: 'FreedomSim',
        name: 'freedom-sim',
        meta: {
          title: '有米-财富自由全景规划 FreedomSim '
        },
        component: () => import('@/views/my-financial-timeline/FreedomSim.vue')
      },
      {
        path: 'PreRetirementPlanner',
        name: 'pre-retirement-planner',
        meta: {
          title: '有米-财富自由达成计划 Pre-Retirement Planner'
        },
        component: () => import('@/views/my-financial-timeline/PreRetirementPlanner.vue')
      },
      {
        path: 'PostRetirementDrawdownSimulator',
        name: 'post-retirement-drawdown-simulator',
        meta: {
          title: '有米-养老储蓄枯竭模拟 Post-Retirement Drawdown Simulator '
          //我的养老储蓄枯竭模拟 Post-Retirement Drawdown Simulator
        },
        component: () => import('@/views/my-financial-timeline/PostRetirementDrawdownSimulator.vue')
      }
    ]
  },
  {
    path: '/pat-lab',
    name: 'pat-Lab',
    component: () => import('@/views/PatLab.vue'),
    children: [
      {
        path: 'myPortfolioVsAssetsComparison',
        name: 'myportfolio-vs-assets-comparison',
        meta: () => {
          const duration = calculateInvestmentDuration();
          const performance = calculatePerformance();
          return {
            title: `我的投资组合 | ${duration}:${performance.portfolio}`,
            meta: [
              {
                name: 'description',
                content: `${duration}收益：${performance.portfolio} VS 沪深300ETF：${performance.hs300}`
              },
              {
                property: 'og:title',
                content: `我的投资组合 | ${duration}:${performance.portfolio}`
              },
              {
                property: 'og:description',
                content: `${duration}收益：${performance.portfolio} VS 沪深300ETF：${performance.hs300}`
              }
            ]
          };
        },
        component: () => import('@/views/pat-lab/MyPortfolioVsAssetsComparison.vue')
      },
      {
        path: 'MeanVarianceAnalyzer',
        name: 'mean-variance-analyzer',
        meta: {
          title: '有米-均值方法分析'
        },
        component: () => import('@/views/MeanVarianceAnalyzer.vue')
      },
      {
        path: 'M2GdpDisplay',
        name: 'm2-gdp-display',
        meta: {
          title: '有米-m2 VS gdp'
        },
        component: () => import('@/views/pat-lab/m2GdpDisplay.vue')
      }
    ]
  },
  {
    path: '/sandbox-practice',
    name: 'sandboxPractice',
    component: () => import('@/views/sandbox-practice/SandboxPractice.vue')
  },
  {
    path: '*',
    redirect: '/home'
  }
];

// 创建路由实例
const router = new VueRouter({
  routes
});

// 动态更新页面标题和 meta 标签
router.beforeEach((to, from, next) => {
  const meta = typeof to.meta === 'function' ? to.meta() : to.meta;
  document.title = meta.title || 'Default Title';
  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    const meta = typeof to.meta === 'function' ? to.meta() : to.meta;
    const metaTags = document.querySelectorAll('[data-vue-meta="true"]');
    metaTags.forEach(tag => tag.parentNode.removeChild(tag));
    if (meta.meta) {
      meta.meta.forEach(metaItem => {
        const tag = document.createElement('meta');
        Object.keys(metaItem).forEach(key => {
          tag.setAttribute(key, metaItem[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    }

   // 确保微信抓取正确的 og:image
   const ogImage = document.querySelector('meta[property="og:image"]');
   if (!ogImage) {
     const imageMeta = document.createElement('meta');
     imageMeta.setAttribute('property', 'og:image');
     imageMeta.setAttribute('content', 'https://www.yomi.bot/logopat.png'); // 确保图片路径可访问
     document.head.appendChild(imageMeta);
   } 
  });
});

export default router;